











































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import SidebarForm from "../SidebarForm.vue";

import BankRequestModel from "@/accounting-portal/models/banks/BankRequestModel";
import BankResponseModel, {
  BankIbanModel,
} from "@/accounting-portal/models/banks/BankResponseModel";
import ValidUtil from "@/shared/utils/ValidUtil";
import ValidationModel, {
  ValidationErrorCode,
} from "@/shared/models/ValidationModel";
import { Dictionary, DictionaryType } from "@/shared/models";

@Component({
  components: {
    SidebarForm,
  },
})
export default class BankForm extends Vue {
  @Prop({ default: false }) value!: boolean;
  @Prop() editingItem!: BankResponseModel | null;

  readonly NAME_RULES = [
    ValidUtil.required(this.$lang("validation.required")),
    ValidUtil.englishLettersOnly(this.$lang("validation.englishLettersOnly")),
    ValidUtil.maxLength(40, this.$lang("validation.maxLength", 40)),
  ];
  readonly CODE_RULES = [
    ValidUtil.required(this.$lang("validation.required")),
    ValidUtil.englishLettersOnly(this.$lang("validation.englishLettersOnly")),
    ValidUtil.capitalLetters(this.$lang("validation.capitalLetters")),
    ValidUtil.maxLength(5, this.$lang("validation.maxLength", 5)),
  ];
  readonly STREET_RULES = [
    ValidUtil.required(this.$lang("validation.required")),
    ValidUtil.englishLettersOnly(this.$lang("validation.englishLettersOnly")),
    ValidUtil.maxLength(50, this.$lang("validation.maxLength", 50)),
  ];
  readonly CITY_RULES = [
    ValidUtil.required(this.$lang("validation.required")),
    ValidUtil.city(this.$lang("validation.invalidCharacters")),
    ValidUtil.maxLength(25, this.$lang("validation.maxLength", 25)),
  ];
  readonly COUNTRY_RULES = [
    ValidUtil.required(this.$lang("validation.required")),
    ValidUtil.englishLettersOnly(this.$lang("validation.englishLettersOnly")),
  ];
  readonly SWIFT_RULES = [
    ValidUtil.required(this.$lang("validation.required")),
    ValidUtil.minLength(8, this.$lang("validation.minLength", 8)),
    ValidUtil.maxLength(11, this.$lang("validation.maxLength", 11)),
  ];
  readonly IBAN_RULES = [
    ValidUtil.required(this.$lang("validation.required")),
    ValidUtil.maxLength(42, this.$lang("validation.minLength", 34)),
  ];
  readonly REQUIRED_RULE = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  localValue = new BankRequestModel();

  get currencies(): Array<Dictionary> {
    return this.$store.state.dictionaryStore[DictionaryType.CURRENCIES].values;
  }

  get isCurrenciesUnfilled(): boolean {
    return this.$store.state.dictionaryStore[DictionaryType.CURRENCIES]
      .unfilled;
  }

  get isDictionariesLoading(): boolean {
    return this.$store.state.dictionaryStore.loadingDictionary;
  }

  get visible(): boolean {
    return this.value;
  }

  set visible(value: boolean) {
    if (!value) {
      this.$store.commit("clearValidations");
      this.localValue = new BankRequestModel();
    }

    this.$emit("input", value);
  }

  get title(): string {
    return this.editingItem
      ? this.$lang("accountingPortal.banks.bankDetailsTitle")
      : this.$lang("accountingPortal.banks.newBankTitle");
  }

  get saving(): boolean {
    return this.$store.state.bankStore.isBankSaving;
  }

  get nameErrorMessages(): Array<string> {
    const isUnique = !this.validations.some(
      ({ field, errorCode }) =>
        field === "name" && errorCode === ValidationErrorCode.NOT_UNIQUE
    );
    const isSizeValid = !this.validations.some(
      ({ field, errorCode }) =>
        field === "name" && errorCode === ValidationErrorCode.SIZE
    );

    if (!isUnique) {
      return [this.$lang("shared.errors.notUnique")];
    }

    if (!isSizeValid) {
      return [this.$lang("validation.maxLength", 40)];
    }

    return [];
  }

  get codeErrorMessages(): Array<string> {
    const isUnique = !this.validations.some(
      ({ field, errorCode }) =>
        field === "code" && errorCode === ValidationErrorCode.NOT_UNIQUE
    );
    const isSizeValid = !this.validations.some(
      ({ field, errorCode }) =>
        field === "code" && errorCode === ValidationErrorCode.SIZE
    );

    if (!isUnique) {
      return [this.$lang("shared.errors.notUnique")];
    }

    if (!isSizeValid) {
      return [this.$lang("validation.maxLength", 5)];
    }

    return [];
  }

  get streetErrorMessages(): Array<string> {
    const isSizeValid = !this.validations.some(
      ({ field, errorCode }) =>
        field === "address.street" && errorCode === ValidationErrorCode.SIZE
    );

    if (!isSizeValid) {
      return [this.$lang("validation.maxLength", 50)];
    }

    return [];
  }

  get cityErrorMessages(): Array<string> {
    const isSizeValid = !this.validations.some(
      ({ field, errorCode }) =>
        field === "address.city" && errorCode === ValidationErrorCode.SIZE
    );
    const isPatternValid = !this.validations.some(
      ({ field, errorCode }) =>
        field === "address.city" && errorCode === ValidationErrorCode.PATTERN
    );

    if (!isSizeValid) {
      return [this.$lang("validation.maxLength", 25)];
    }

    if (!isPatternValid) {
      return [this.$lang("accountingPortal.banks.cityValidationPattern")];
    }

    return [];
  }

  get countryErrorMessages(): Array<string> {
    const isPatternValid = !this.validations.some(
      ({ field, errorCode }) =>
        field === "address.country" && errorCode === ValidationErrorCode.PATTERN
    );

    if (!isPatternValid) {
      return [this.$lang("validation.englishLettersOnly")];
    }

    return [];
  }

  get swiftErrorMessages(): Array<string> {
    const isPatternValid = !this.validations.some(
      ({ field, errorCode }) =>
        field === "swift" && errorCode === ValidationErrorCode.PATTERN
    );

    if (!isPatternValid) {
      return [this.$lang("accountingPortal.banks.invalidSwift")];
    }

    return [];
  }

  get validations(): Array<ValidationModel> {
    return this.$store.state.validationStore.validations;
  }

  @Watch("editingItem", { deep: true })
  watchEditingItem(value: BankResponseModel | null) {
    if (!value) {
      return;
    }

    this.localValue = BankRequestModel.of(cloneDeep(value));
  }

  @Watch("localValue.name", { deep: true })
  watchLocalValueName() {
    if (this.nameErrorMessages.length) {
      this.$store.commit("clearValidationByField", "name");
    }
  }

  @Watch("localValue.code", { deep: true })
  watchLocalValueCode() {
    if (this.codeErrorMessages.length) {
      this.$store.commit("clearValidationByField", "code");
    }
  }

  @Watch("localValue.address.street", { deep: true })
  watchLocalValueStreet() {
    if (this.streetErrorMessages.length) {
      this.$store.commit("clearValidationByField", "address.street");
    }
  }

  @Watch("localValue.address.city", { deep: true })
  watchLocalValueCity() {
    if (this.cityErrorMessages.length) {
      this.$store.commit("clearValidationByField", "address.city");
    }
  }

  @Watch("localValue.address.country", { deep: true })
  watchLocalValueCountry() {
    if (this.countryErrorMessages.length) {
      this.$store.commit("clearValidationByField", "address.country");
    }
  }

  @Watch("localValue.swift", { deep: true })
  watchLocalValueSwift() {
    if (this.swiftErrorMessages.length) {
      this.$store.commit("clearValidationByField", "swift");
    }
  }

  @Watch("localValue.ibans", { deep: true })
  watchLocalValueIbans(ibans: Array<BankIbanModel>) {
    ibans.forEach((_, index) => {
      if (this.getIbanErrorMessage(index)) {
        this.$store.commit("clearValidationByField", `ibans[${index}].iban`);
      }
    });
  }

  @Watch("visible")
  watchVisibility(visible: boolean) {
    if (!visible || !this.isCurrenciesUnfilled) {
      return;
    }

    this.$store.dispatch("loadDictionaries", {
      dictionaryTypes: [DictionaryType.CURRENCIES],
    });
  }

  handleAddingIban() {
    this.localValue.ibans.push(new BankIbanModel());
  }

  handleDeleteIbanByIndex(index: number) {
    this.localValue.ibans.splice(index, 1);
  }

  getIbanErrorMessage(index: number): Array<string> {
    const isPatternValid = !this.validations.some(
      ({ field, errorCode }) =>
        field === `ibans[${index}].iban` &&
        errorCode === ValidationErrorCode.PATTERN
    );

    if (!isPatternValid) {
      return [this.$lang("accountingPortal.banks.invalidIban")];
    }

    return [];
  }

  async handleSave(closeDialog: () => void) {
    await this.$store.dispatch("validateBank", {
      id: this.editingItem ? this.editingItem.id : undefined,
      ...BankRequestModel.ofRequest(this.localValue),
    });

    if (this.editingItem) {
      await this.$store.dispatch("updateBank", {
        id: this.editingItem.id,
        payload: BankRequestModel.ofRequest(this.localValue),
      });
    } else {
      await this.$store.dispatch(
        "createBank",
        BankRequestModel.ofRequest(this.localValue)
      );
    }

    closeDialog();
  }
}
